.gradient-btn {
  background: linear-gradient(134.14deg, #0083FD 0%, #7FE7FF 98.51%);
  padding: 16px 60px;
  color: white;
  border-radius: 50px;
  border: none;
  width: fit-content;
  font-weight: bold;
  line-height: 30px;
  font-size: 22px;
  margin-bottom: 60px;
}

.welcome {
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  margin: 40px 0;
  text-align: center;
}
.welcome-bg {
  /* width: 100%; */
  margin: 0 auto;
  text-align: center;
  height: 470px;
  margin-bottom: 40px;
}
.instructions {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 8px 0;

  text-align: center;

  color: white;
}
.video {
  margin: 0 auto;
  width: auto;
  text-align: center;
}
.iframe {
  height: 470px;
  width: 70%;
  margin-bottom: 40px;
}
.last-inst {
  margin-top: 50px;
}

.welcome-banner {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .last-inst {
    margin-top: 40px;
  }
  .welcome {
    font-size: 20px;
    margin: 20px 0;
  }

 
  .welcome-bg {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    height: auto;
    margin-bottom: 40px;
  }

  .gradient-btn {
    font-size: 18px;
  }
}




@media (max-width: 468px) {

  .gradient-btn {
    font-size: 18px;
    padding-left: 18px;
    padding-right: 18px;
  }
}

