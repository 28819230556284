.feature {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 10px;
}

.feature p {
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    max-width: 600px;
}

.feature .gradient-btn {
    background: linear-gradient(134.14deg, #0083FD 0%, #7FE7FF 98.51%);
    padding: 16px 60px;
    color: white;
    border-radius: 50px;
    border: none;
    width: fit-content;
    font-weight: bold;
    line-height: 30px;
    font-size: 22px;
    margin-bottom: 60px;
}

.feature .items {
    max-width: 840px;
    display: flex;
    border-radius: 30px;
    background-color: white;
    padding: 40px 80px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 120px 0;
}

.feature .items > div {
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.feature .items > div > p {
    font-size: 16px;
    line-height: 18px;
    justify-content: center;
    color: black;
}

.feature h2 {
    font-size: 38px;
    line-height: 50px;
    text-align: center;
}

.feature h3 {
    margin-top: 10px;
    font-size: 25px;
    font-weight: 500px;
    text-align: center;
}

.feature .comming {
    padding: 12px 40px 10px 40px;
    background: #0083FD;
    border-radius: 6px;
    color: white;
    border: none;
}

.feature .market {
    margin-top: 60px;
    margin-bottom: 100px;
    max-width: 840px;
}


@media screen and (max-width: 600px) {
    .feature .items {
        padding: 28px;
    }

    .feature .items > div {
        width: 140px;
        height: 140px;
        display: flex;
    }

    .feature h2 {
        font-size: 30px;
        line-height: 40px;
    }
    
    .feature h3 {
        font-size: 20px;
    }

    .feature .gradient-btn {
        padding: 12px 24px;
        margin-bottom: 40px;
        font-size: 18px;
    }
}