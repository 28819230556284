.meeting-people-mobile {
  display: none;
}
.main-banner {
  padding-top: 150px;
  display: flex;
  justify-content: space-between;
}
.banner-text {
  margin-top: 70px;
  width: 50%;
}
.banner-img {
  width: 50%;
}
.banner-img-2 {
  width: 50%;
}
.banner-img-2 img {
  width: 450px;
}

.banner-text h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  color: #0083fd;
}

.banner-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 30px 0;
  color: #ffffff;
}
.banner-text h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-top: 50px;
  color: #000000;
}
.blue-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #0083fd;
  outline: none;
  cursor: pointer;
  color: #ffffff;

  background: #0083fd;
  padding: 12px 40px;
  transition: all 0.3s ease;
  border-radius: 6px;
  text-decoration: none;
}
.blue-btn:hover {
  transition: all 0.3s ease;
  background-color: #ffffff;
  color: #0083fd;
  border: 1px solid #0083fd;
}

.btn-container {
  display: flex;
  margin-top: 20px;
  gap: 40px;
  align-items: center;
}

.btn-container .simple-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #0083fd;
}

@media (max-width: 940px) {
  .main-banner {
    flex-direction: column-reverse;
  }
  .banner-img {
    text-align: center;
  }
  .banner-img img {
    width: 500px;
  }
  .banner-text {
    width: 100%;
  }
}
  



@media (max-width: 940px) {
  .banner-img, .banner-img-2{
    display: flex;
    width: 100% !important;
    justify-content: center !important;
  }
  .banner-img img {
    width: 80%;
  }
  .main-banner {
    padding-top: 117px;
    flex-direction: column;
  }
  .banner-text {
    margin-top: 60px;
    text-align: center;
  }
  .banner-text h1 {
    font-size: 25px;
  }
  .banner-text h3 {
    margin-top: 30px;
  }
  .btn-container {
    justify-content: center;
  }
  .blue-image-1 {
    width: 80%;
  }

  .banner-img-2 img {
    width: 80% !important;
  }

  .change-orientation {
    flex-direction: column !important;
  }
}

@media (max-width: 500px) {
  .meeting-people-desktop {
    display: none;
  }
  .meeting-people-mobile {
    display: block;
  }
}