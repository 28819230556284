.early-access {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.early-access .logo-section {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
    max-width: 1240px;
}

.early-access .logo-section .connect {
    position: absolute;
    right: 0px;
    top: -25px;
}

.early-access .info-text {
    text-align: center;
    font-size: 20px;
    color: white;
    font-weight: 400;
    margin-top: 60px;
}

.early-access .info-text i {
    font-weight: 300;
    margin-top: 20px;
    display: block;
}


.early-access .step-container {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 96%;
    gap: 24px;
    margin-top: 40px;
}

.early-access .step {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  gap: 28px;
}

.early-access .number {
    background-color: #332D36;
    color: #696969;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    border: none;
    outline: none;
}


.early-access .btn {
    background-color: #332D36;
    color: #696969;
    height: 45px;   
    border-radius: 22.5px;
    border: none;
    outline: none;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding: 0px 28px;
    font-size: 20px;
    font-weight: 700;
}

.early-access .done {
    font-size: 16px;
    font-weight: 400;
}

.early-access .active {
    background-color: #FFFFFF;
    color: black !important;
}

.restart {
    margin: 25px 0px;
    color: white;
    cursor: pointer;
}

.early-access .next {
    background-color: #0083FD;
    border-radius: 20px;
    color: white;
    height: 48px;
    width: 100%;
    justify-content: center;
    cursor: pointer;
}

.early-access .next:disabled {
    background-color: #4D4D4D;
    color: #FFFFFF90;
}

.early-access .nft-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.early-access .nft-section .balance {
    color: red;
    font-style: italic;
    margin-bottom: 14px;
}

.early-access .last {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.early-access .test-flight-btn {
    background-color: #0083FD;
    max-width: 165px;
    font-weight: 400;
    font-size: 13px;
    color: white;
    justify-content: flex-start;
    padding: 10px 20px;
    cursor: pointer;
}


.early-access .test-flight-btn:disabled {
    background-color: #4D4D4D;
}


.early-access .test-flight-btn .icon-bg {
    background-color: white;
    border-radius: 20px;
    padding: 3px;
    width: 25px;
    height: 25px;
    margin-right: 6px;
}

.modal-wrapper {
    z-index: 100000;
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-wrapper .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000050;
}

@media (max-width:600px) {


    .early-access .step {
        font-size: 16px;
        gap: 16px;
    }
    
    .early-access .number {

    }
    
    
    .early-access .btn {
        font-size: 16px;
    }
    
    .early-access .done {
        font-size: 12px;
    }
        
}