.team-bg {
  position: relative;
}
.team-bg-img {
  border-radius: 30px;
  width: 100%;
}
.team-bg h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  margin: auto;
  right: 0;
  bottom: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  line-height: 50px;
}
.bluBlok{
  display: flex;
  align-items: center;
  justify-content: center;
 }
.team-flex {
  margin-top: 80px;
  display: flex;
  justify-content: space-evenly;
}
.single-team {
  width: 20%;
}

.single-team-img {
  width: 100%;
  border-radius: 20px;
}

.single-team p {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  color: white;
  text-align: center;
}
.mini-img{
  width: 20px;
  height: 20px;
  padding-right: 5px;
}
.team-bg {
  overflow: hidden;
}
.team-flex {
  flex-wrap: wrap;
}
@media (max-width: 940px) {
  .team-bg-img {
    border-radius: 0;
    width: auto;
  }
  .team-flex {
    row-gap: 30px;
  }
  .single-team {
    width: 45%;
  }
  .single-team p {
    padding-inline: 0px;
  }
  .mini-img{
    width: 20px;
    height: 20px;
    float:left;
  }
}

@media (max-width: 500px) {
  .single-team {
    width: 46%;
  }
  .team-bg h1 {
    font-size: 15px;
    line-height: 25px;
  }
}
