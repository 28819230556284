.main-banner {
  padding-top: 150px;
  display: flex;
  justify-content: space-between;
}

.banner-text {
  margin-top: 70px;
  width: 50%;
}

.banner-img {
  width: 50%;
}

.banner-img img {
  width: 450px;
}

.banner-img-2 {
  width: 50%;
}

.banner-img-2 img {
  width: 450px;
}

.banner-text h1 {
  font-style: bold;
  font-weight: 700;
  font-size: 38px;
  color: #ffffff;
}

.banner-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 30px 0;
  color: #ffffff;
}

.banner-text h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-top: 50px;
  color: #000000;
}

.blue-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #0083fd;
  outline: none;
  cursor: pointer;
  color: #ffffff;
  background: #0083fd;
  padding: 12px 40px;
  transition: all 0.3s ease;
  border-radius: 6px;
  text-decoration: none;
}

.blue-btn:hover {
  transition: all 0.3s ease;
  background-color: #ffffff;
  color: #0083fd;
  border: 1px solid #0083fd;
}

.btn-container {
  display: flex;
  margin-top: 20px;
  gap: 40px;
  align-items: center;
}

.btn-container .simple-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
  
  @media (max-width: 940px) {
    .main-banner {
      flex-direction: column-reverse;
    }
    .banner-img {
      text-align: center;
    }
    .banner-img img {
      width: 450px;
    }
    .banner-img-2 {
      text-align: center;
    }
    .banner-img-2 img {
      width: 450px;
    }

    .banner-text {
      width: 100%;
    }
    .intergration-container {
      flex-direction: column;
    }
  }
  
  @media (max-width: 600px) {
    .banner-img, .banner-img-2{
      margin: 0 auto;
    }
    .banner-img img {
      width: 110%;
    }
    .banner-img-2 img {
      display: flex;
      justify-content: center;
      width: 140%;
    }
    .main-banner {
      padding-top: 60px;
    }
    .banner-text {
      margin-top: 60px;
    }
    .banner-text h1 {
      font-size: 25px;
    }
    .banner-text h3 {
      margin-top: 30px;
    }
    .column-reverse-mobile {
      flex-direction: column-reverse;
    }
    .column-mobile {
      flex-direction: column;
    }
    .banner-img > img {
      width: 80%;
      height: auto;
    }
  }
  