* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Red Hat Display';
}

.background{
    background-color: #031227;
}

html{
    scroll-behavior: smooth;
}