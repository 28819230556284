.platfrom-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: #ffffff;
}
.text-center {
  text-align: center;
}

.single-social {
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 200px; /* fixed width for better animation effect */
  min-width: 200px;
  min-height: 200px;
  background: transparent;
  border-radius: 10px;
  padding: 20px;
  margin: 0;
  border: none;
}

.single-social img {
  width: 100%;
  height: auto;
}
/* scrolling banner effect */
.social-flex {
  display: flex;
  gap: 20px;
  overflow: hidden;
  padding: 20px 0;
  margin: 0 0px;
  min-width: 100%;
  width: calc(100% + 40px);
  position: relative;
}

.scroll-section {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.scroll-content {
  display: flex;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-235px * 11)
    ); /* Adjust based on number of logos */
  }
}

@media (max-width: 1300px) {
  .social-flex {
    gap: 10px;
  }
  .single-social {
    width: calc(40% - 40px);
  }
}

@media (max-width: 768px) {
  .platfrom-heading {
    font-size: 25px;
  }
  .single-social {
    width: calc(46% - 40px);
  }
}

@media (max-width: 420px) {
  .single-social {
    width: calc(100% - 40px);
  }
}

/* Hide scrollbar */
.social-flex::-webkit-scrollbar {
  display: none;
}
.social-flex {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
