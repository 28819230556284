body {
  margin: 0;
  padding: 0;
  font-family: "Red Hat Display";
  overflow-x: hidden;
  background-color: #031227;
}
.container-width {
  margin: 0 auto;
  max-width: 1300px;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
li {
  list-style: none;
}
.section-space {
  padding-top: 120px;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

.theme-color {
  color: #000000 !important;
}

@media (max-width: 1320px) {
  .container-width {
    padding-inline: 20px;
  }
}
@media (max-width: 940px) {
  .section-space {
    padding-top: 90px;
  }
}
@media (max-width: 600px) {
  .container-width {
    padding-inline: 15px;
  }
}
