.white-paper-btn {
  background-color: #FFFFFF;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #0083fd;
  outline: none;
  cursor: pointer;
  color: #031227;;
  background: white;
  padding: 12px 50px;
  transition: all 0.3s ease;
  border-radius: 6px;
  text-decoration: none;
}
.white-paper-btn:hover {
  transition: all 0.3s ease;
  background-color: #ffffff;
  color: #031227;
  border: 1px solid #0083fd;
}

.banner-header {
  margin-top: 40px;
}

.main-banner {
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
}
.banner-text {
  margin-top: 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
}
.banner-img {
  display: flex;
  justify-content: center;
}

.banner-text h1 {
  font-style: normal;
  font-size: 38px;
  color: white;
}

.banner-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 30px 0;
  color: #ffffff;
}

.banner-text h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-top: 50px;
  color: white;
}

.blue-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #0042EB;
  outline: none;
  cursor: pointer;
  color: #ffffff;

  background: #0083fd;
  padding: 12px 40px;
  transition: all 0.3s ease;
  border-radius: 6px;
  text-decoration: none;
}
.blue-btn:hover {
  transition: all 0.3s ease;
  background-color: #ffffff;
  color: #0083fd;
  border: 1px solid #0083fd;
}

.btn-container {
  display: flex;
  margin-top: 20px;
  gap: 40px;
  align-items: center;
}

.btn-container .simple-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  color: #ffffff;
}

.banner-img img {

}

.we-won {
  display: flex;
  background: linear-gradient(90deg, #FFFFFF 0%, #00AAFF 100%);
  padding: 8px 8px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  max-width: 480px;
  border-radius: 16px;
  margin-top: 36px;
  margin-top: 36px;
  justify-content: center;
  align-items: center;
  height: 56px;
  text-decoration: none;

}

.we-won img {
  margin-right: 6px;
  height: 43px;
  width: 28px;
}

.we-won:hover {
  outline: none;
}

.we-won p {
  color: black !important;
  font-size: 16px;
  font-weight: 500 !important;
}

.header-banner-img img {
  max-height: 650px;
  max-width: 650px;
  width: 70%;
  height: 100%;
}

.hero-platforms {
  display: flex;
  align-items: center;
}

.hero-bg-img {
  position: absolute;
  top: 70px;
  right: 0;
  width:  44%;
  max-width: 900px;
  height: 100vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../assets/img/new/hero-bg-right.png);
}

.hero-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  margin-top: 8%;
}



@media (max-width: 1600px) {

  .hero-bg-img {
    width: 44%;
  }
  

}


@media (max-width: 1440px) {

  .hero-left {
    margin-top: 0%;
  }

  .hero-bg-img {
    width: 54%;
  }
  

}

@media (max-width: 940px) {

  .hero-platforms  {
    display: flex;
    justify-content: center;
  }

  .hero-bg-img {
    display: none;
  }

  .hero-left {
    margin-top: 0%;
    text-align: center;
  }

  .main-banner {
    flex-direction: column-reverse;
  }
  .banner-img {
    text-align: center;
  }
  .banner-img img {

  }
  .banner-text {
    width: 100%;
  }

  .banner-logo-container {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .hero-platforms  {
    display: flex;
    justify-content: center;
  }
  .banner-img img {
    width: 86%;
  }
  .main-banner {
    padding-top: 117px;
  }
  .banner-text {
    margin-top: 60px;
  }
  .banner-text h1 {
    font-size: 25px;
  }
  .banner-text h3 {
    margin-top: 30px;
  }

  .we-won p {
    font-size: 12px;
  }

  .we-won img {
    width: 20px;
    height: auto;
  }

  .banner-logo-container {
    display: flex;
    justify-content: center;
  }

}
