.buy-token .switch-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    padding: 0px 10px;
}

.buy-token .switch {
    width: 360px;
    height: 40px;
    border-radius: 5px;
    background-color: #00AAFF;
}

.buy-token > h2 {
    color: white;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
}

.buy-token > h2 > span {
    color: #00AAFF;
}

.switch > button {
    border: none;
    width: 50%;
    height: 40px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    background-color: transparent;
}

.switch > .active {
    background-color: white;
    color: black !important;
}

.num-main > p {
    color: white;
}

.disclaimer {
    text-align: center;
    color: white;
    margin-top: 60px;
    font-size: 14px;
    /* font-family: Inter; */
    /* line-height: 14.5px; */
}

.disclaimer > span:first-child {
    color: #FF0000;
    /* font-family: Inter; */
}

.disclaimer > .token {
    color: #0083FD;
    text-decoration: none;
    /* font-family: Inter; */
}