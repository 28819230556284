.thirdweb-pay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.thirdweb-pay .embedded-pay {
    max-width: 360px;
    width: 100%;
}

.thirdweb-pay .platform-heading {
    color: #007bff;
}