.f-banner-btn {
    padding: 20px 150px !important;
}

.network-container {
    width: 100%;
}

.network-row {
    display: flex;
    justify-content: space-around;
    align-items: end;
    width: 100%;
    margin-bottom: 80px;
}

.network-card {
    font-size: 14px;
}

.network-animation {
    display: flex;
    justify-content: center;
    width: 100%;
}

.network-card h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    font-family: Comfortaa;
}

.network-card p {
    line-height: 26px;
    margin-top: 16px;
    font-family: Comfortaa;
    font-size: 16px;
}

.network-mobile {
    display: none;
}


@media screen and (max-width: 960px) {
    .f-banner-btn {
        padding: 20px 34px !important;
    }

    .network-row {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .network-card {
        text-align: center;
    }

    .network-card h2 {
        font-size: 28px;
    }

    .network-card p {
        margin-bottom: 4px;
    }
}

@media screen and (max-width: 600px) {
    .network-card h2 {
        font-size: 24px;
    }

    .network-card p {
        font-size: 14px;
    }

    .network-desktop {
        display: none;
    }

    .network-mobile {
        display: block;
    }

    .container-width {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .f-banner-btn {
        padding: 20px 20px !important;
        font-size: 14px !important;
    }
    
}