.graph-section {
  margin-top: 70px;
}
.token-logo{
  margin-bottom: 2rem;
  max-width: 25%;
}
.circulation {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.coin-name {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
}
.circulation-graph {
  width: 50%;
}
.circulation-graph img {
  width: 100%;
}
.coin-name img {
  width: 120px;
}
.coin-name p {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
}
.circle-graph {
  width: 100%;
  margin-top: 80px;
  text-align: center;
}
.circle-graph img {
  width: 97%;
}
.altwr {
  margin-top: 80px;
}
.team-finance > span {
  position: relative;
}
.team-finance .arrow {
  position: absolute;
  top: -120px;
  left: 210px;
}
.team-finance .text {
  position: absolute;
  top: -120px;
  left: 210px;
  color: white;
  width: 300px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.play-heading {
  color: white;
  text-align: center;
}


@media (max-width: 768px) {
  .circulation {
    flex-direction: column;
  }
  .circle-graph img,
  .circulation-graph,
  .coin-name {
    width: 100%;
  }
  .circle-graph {
    margin-top: 100px;
  }
  .team-finance .arrow {
    display: none;
  }
  .team-finance .text {
    display: none;
  }

}
